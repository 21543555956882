.loaderHolder {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 99;
    .loader-shade{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background: white;
        opacity: .7;
        cursor: no-drop;
    }
    &.close{
        display: none;
    }
}

.loader {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    position: fixed;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media(min-width: 768px){
        left: calc(50% + 115px );
    }
}

/* LOADER 1 */

#loader-1:before,
#loader-1:after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 10px solid transparent;
    border-top-color: #06aefe;
}

#loader-1:before {
    z-index: 100;
    animation: spin 1s infinite;
}

#loader-1:after {
    border: 10px solid #ccc;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
