.std-table-height{
    height: 330px;
}
.scroll{
    overflow: auto;
}

.w-2-col{
    width: 68px;
}
.w-3-col{
    width: 95px;
}

.w-3-col-ex{
    width: 130px;
}
