:root{
  --root-blue: #0089cf;
  --root-bg-color: #F7FBFE;
  scrollbar-width: thin;
}

.logo{
    height: 40px;
    width: 130px;
}

.bar{
  color: #C5C7D4;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 2px solid #ccc;
    margin: 1em 0;
    padding: 0;
    margin-bottom: 0;
  }

  .left-icon li a i{
    color: #C5C7D4;
    padding-top: 30px;
  }

  .left-icon li a i:hover{
    color: #0089CF;
  }

  .right-content-section{
    background-color: var(--root-bg-color);
  }
  /*overview cards start*/
  .common-card-bottom{
    background-color: var(--root-blue) ;
    color: #ffffff;
  }
  .card-box-shadow{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.3);
  }
  .atm-card-top{
    background-image: linear-gradient(to right, #FA5772 , #FB8095);
  }
  .customer-card-top{
    background-image: linear-gradient(to right, #fcb932 , #FDC9CA);
  }
  .customer-card-top{
    background-image: linear-gradient(to right, #fcb932 , #FDC96A);
  }
  .dispense-card-top{
    background-image: linear-gradient(to right, #49affa , #6bc9fb);
  }
  .recharge-card-top{
    background-image: linear-gradient(to right, #3acea9 , #5ddec0);
  }
   /*overview cards end*/


   /*withdrawal overview start*/
   .last-withdrawal-shadow{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.1);
   }
   .last-withdrawal-title{
     color: var(--root-blue);
   }


   /*withdrawal overview start*

/*chart start*/

.chart-box-shadow{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/*chart end*/

/*map start*/
  .map-topbar{
    background-color: var(--root-blue);
    padding-top: 20px;
    padding-bottom: 20px;
  }
/*map end*/

/*map start*/
.map-shadow{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.map-topbar{
  background-color: var(--root-blue);
}
/*map end*/

/*filter topbar start*/
.filter-topbar{
  background-color: var(--root-blue);
}
/*filter topbar end*/

/*table start*/

.th-color{
  color: var(--root-blue);
}
.table-shadow{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

/*table end*/

.row-3-col{
  height: 100%;
}

.row-3 .header{
  height: 35px !important;
}
.row-3 .details{
  height: calc(100% - 35px);
}
table {
  border-collapse: inherit;
}
::-webkit-scrollbar{
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb{
  background: #aaa;
}
.latest-transaction{
  scrollbar-width: thin;
}

.theader{
  position: sticky;
  top: 0;
}

hr {
  margin-top: 0rem;
  margin-bottom: 0rem;
  border: 0;
  border-top: 1px solid rgba(219, 216, 216, 0.1);
}

label {
  color: #aca6a3;
  display: block;
  font-size: 10px;
  }

  canvas{
    cursor: pointer;
    transition: transform .3s;
  }
  canvas:hover{
    transform: scale(1.05)
  }

  .spinner-icon{
    position: absolute;
    left: 50%;
    top: 30%;
    z-index: 1;
    margin: -20px 0 0 -20px;
    width: 50px;
    height: 50px;
  }