.m-t-20{
    margin-top: 20px;
}
.m-t-40{
    margin-top: 40px;
}
.m-b-0{
    margin-bottom: 0px;
}
.m-t-8{
    margin-top: 8px;
}
.form-controll-margin{
    margin-bottom: 5px;
}
.m-t-10{
    margin-top: 10px;
}
.m-r-10{
    margin-right: 10px;
}
.m-b-10{
    margin-bottom: 10px;
}
