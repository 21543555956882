.info-box{
    min-height: 70px;
    position: relative;
}
.info-box-content{
    margin-left: 140px;
    position: relative;
    top: 16px;
    font-weight: 600;
    line-height: 15px;
}
.info-box-icon{
    height: 70px;
    width: 140px;
    font-size: 22px;
    line-height: 70px;
    &.line-2{
        line-height: 56px;
    }
}
.info-box-liter{
    position: absolute;
    left: 56px;
    top: 40px;
    color: white;
    font-weight: bold;
}
