@import
    "base",
    "header_logo",
    "infobox",
    "label",
    "table",
    "datepicker",
    "box"
    ;
.sidebar-menu > li > a span{
    padding-left: 10px;
}
