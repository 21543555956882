.label{
    &.label-inactive{
        background-color: silver;
    }
    &.label-drink{
        background-color: #00c0ef;
    }
}
label{
    font-weight: normal;
}
.control-label{
    &.text-left{
        text-align: left;
    }
}
