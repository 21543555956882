$btn_success_color: green;
$btn_danger_color: red;
$btn_action_bg: transparent;
$btn_theme_color: #0fb1fe;
.btn{
    &.btn-table-sm{
        font-size: 10px;
        padding: 2px 6px;
    }
}
.btn-col-success{
    color: $btn_success_color;
    &:hover,
    &:focus{
        color: $btn_success_color;
        opacity: .8;
    }
}
.btn-col-theme{
    color: gray;
    &:hover,
    &:focus{
        color: $btn_theme_color;
        opacity: .8;
    }
}
.btn-col-danger{
    color: gray;
    &:hover,
    &:focus{
        color: $btn_danger_color;
        opacity: .8;
    }
}

.btn-action{
    background-color: $btn_action_bg;
    margin-right: 12px;
}
